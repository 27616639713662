import Typography from 'styles/typography/Typograpy';
import { useTranslate } from 'stylesHooks';
import { IBenefit } from 'types/Member';
import { formatPercentage, formatPrice } from 'utils/utils';

const MemberValueBenefit = (props: { benefit: IBenefit }) => {
	const { t: tDbLabels } = useTranslate('dbLabels');
	const { coPay, coInsuranceAmount, maxAmount, memberBenefitLabel } =
		props.benefit;

	return (
		<Typography variant="body" minWidth={160} maxWidth={160} paddingRight={2.5}>
			{tDbLabels(memberBenefitLabel, {
				coPay: formatPrice(coPay, true),
				coInsuranceAmount: formatPercentage(coInsuranceAmount),
				maxAmount: formatPrice(maxAmount, true),
			})}
		</Typography>
	);
};

export default MemberValueBenefit;
