import { Box as BoxMui, BoxProps as BoxPropsMui } from '@mui/material';

import styled from 'styled-components';

type BoxProps = BoxPropsMui;

const StyledBox = styled(BoxMui)<BoxProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 15px;
`;

const TableFooterBox: React.FC<BoxProps> = props => {
	return <StyledBox {...props}>{props.children}</StyledBox>;
};

export default TableFooterBox;
