import ClaimsOperationIcon, {
	Sign,
} from 'components/commons/ClaimIcons/ClaimsOperationIcon.component';
import {
	Divider,
	Paper,
	Table,
	TableBody,
	TableBox,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from 'styles';
import {
	formatPrice,
	getShortDate,
	useGetServiceTypeInitials,
} from 'utils/utils';

import useDevice from 'hooks/DesktopDevice.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import TableFooterBox from 'styles/box/TableFooterBox';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';
import LegendForServiceType from './LegendForServiceType.component';

const ClaimPaymentDetails = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { claimData: claim, errorCode } = useSelector(
		(store: RootState) => store.claim,
	);
	const { isTabletDevice } = useDevice();
	const { getInitialLabel } = useGetServiceTypeInitials();
	const {
		amount: { components },
	} = claim;
	const {
		amount: {
			total: {
				retailAmount,
				contractualAdjustmentAmount,
				allowablePlanPay,
				memberPayAmount,
				total,
				grossRetail,
				inStorePromotion,
			},
		},
	} = claim;

	if (errorCode !== 0) {
		return null;
	}

	return (
		<TableBox>
			<TableContainer component={Paper} sx={{ pb: '20px' }}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell align="left" sx={{ paddingLeft: '20px !important' }}>
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.serviceDate')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.serviceCode')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.st')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.grossRetail')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.MINUS} />
							</TableCell>
							<TableCell align="center">
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.inStorePromotion')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.EQUAL} />
							</TableCell>
							<TableCell align="center">
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.retailAmount')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.MINUS} />
							</TableCell>
							<TableCell align="center">
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.contractaulAdjiustment')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.planPayment')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.PLUS} />
							</TableCell>
							<TableCell align="center">
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.memberOutOfPocket')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.EQUAL} />
							</TableCell>
							<TableCell
								align="center"
								sx={{ paddingRight: isTabletDevice ? '40px !important' : 'auto' }}
							>
								<Typography
									variant={isTabletDevice ? 'bodyLinkTabletOneLine' : 'bodyLinkTablet'}
								>
									{tClientLabels('claim.table.total')}
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{components.map((row: any, index: number) => {
							const {
								retailAmount,
								serviceId,
								serviceName,
								contractualAdjustmentAmount,
								planPayAmount,
								allowablePlanPay,
								memberPayAmount,
								total,
								grossRetail,
								inStorePromotion,
							} = row;

							return (
								<TableRow key={index}>
									<TableCell
										component="th"
										scope="row"
										align="left"
										sx={{
											paddingTop: index === 0 ? '20px !important' : 'auto',
											paddingLeft: '20px !important',
										}}
									>
										<Typography variant="body">
											{getShortDate(claim.claimDate)}
										</Typography>
									</TableCell>
									<TableCell
										align="center"
										sx={{ paddingTop: index === 0 ? '20px !important' : 'auto' }}
									>
										<Typography variant="body">{serviceId ? serviceId : '-'}</Typography>
									</TableCell>
									<TableCell
										align="center"
										sx={{ paddingTop: index === 0 ? '20px !important' : 'auto' }}
									>
										<Typography variant="body">{getInitialLabel(serviceName)}</Typography>
									</TableCell>
									<TableCell
										align="center"
										sx={{ paddingTop: index === 0 ? '20px !important' : 'auto' }}
									>
										<Typography variant="body">
											{grossRetail ? formatPrice(grossRetail) : formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell
										align="center"
										sx={{ paddingTop: index === 0 ? '20px !important' : 'auto' }}
									>
										<Typography variant="body">
											{inStorePromotion
												? formatPrice(inStorePromotion)
												: formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell
										align="center"
										sx={{ paddingTop: index === 0 ? '20px !important' : 'auto' }}
									>
										<Typography variant="body">
											{retailAmount ? formatPrice(retailAmount) : formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell
										align="center"
										sx={{ paddingTop: index === 0 ? '20px !important' : 'auto' }}
									>
										<Typography variant="body">
											{contractualAdjustmentAmount
												? formatPrice(contractualAdjustmentAmount)
												: formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell
										align="center"
										sx={{ paddingTop: index === 0 ? '20px !important' : 'auto' }}
									>
										<Typography variant="body">
											{planPayAmount ? formatPrice(allowablePlanPay) : formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell
										align="center"
										sx={{ paddingTop: index === 0 ? '20px !important' : 'auto' }}
									>
										<Typography variant="body">
											{memberPayAmount
												? formatPrice(memberPayAmount)
												: formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell
										align="center"
										sx={{
											paddingTop: index === 0 ? '20px !important' : 'auto',
											paddingRight: isTabletDevice ? '20px !important' : 'auto',
										}}
									>
										<Typography variant="body">
											{total ? formatPrice(total) : formatPrice('0.00')}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}

						<TableRow>
							<TableCell colSpan={15} sx={{ paddingTop: '0px !important' }}>
								<Divider light sx={{ width: '100%' }} />
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell
								component="th"
								scope="row"
								align="left"
								sx={{ paddingLeft: '20px !important', paddingTop: '0px !important' }}
							>
								<Typography variant="bodyLink">
									{tClientLabels('claim.table.total')}:
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingTop: '0px !important' }}>
								<Typography variant="bodyLink">
									{grossRetail ? formatPrice(grossRetail) : formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingTop: '0px !important' }}>
								<Typography variant="bodyLink">
									{inStorePromotion
										? formatPrice(inStorePromotion)
										: formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingTop: '0px !important' }}>
								<Typography variant="bodyLink">
									{retailAmount ? formatPrice(retailAmount) : formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingTop: '0px !important' }}>
								<Typography variant="bodyLink">
									{contractualAdjustmentAmount
										? formatPrice(contractualAdjustmentAmount)
										: formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center" sx={{ paddingTop: '0px !important' }}>
								<Typography variant="bodyLink">
									{allowablePlanPay
										? formatPrice(allowablePlanPay)
										: formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center" sx={{ paddingTop: '0px !important' }}>
								<Typography variant="bodyLink">
									{memberPayAmount ? formatPrice(memberPayAmount) : formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center"></TableCell>
							<TableCell
								align="center"
								sx={{
									paddingTop: '0px !important',
									paddingRight: isTabletDevice ? '20px !important' : 'auto',
								}}
							>
								<Typography variant="bodyLink">
									{total ? formatPrice(total) : formatPrice('0.00')}
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<TableFooterBox>
				<LegendForServiceType />
			</TableFooterBox>
		</TableBox>
	);
};

export default ClaimPaymentDetails;
