import { getFullDate, getShortDate } from 'utils/utils';

import useDevice from 'hooks/DesktopDevice.hook';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import CompositeTypography from 'styles/typography/CompositeTypography.styles';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const MemberDetail: FC = () => {
	const { isDesktop } = useDevice();
	const { member } = useSelector((store: RootState) => store.member);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				width: '100%',
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<CompositeTypography
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
					label={tClientLabels('memberDetail.labels.memberName')}
					value={member?.memberName ? member?.memberName : '-'}
				/>
				<CompositeTypography
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
					label={tClientLabels('memberDetail.labels.carrier')}
					value={member?.carrierName ? member?.carrierName : '-'}
				/>
				<CompositeTypography
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
					label={tClientLabels('memberDetail.labels.dateOfService')}
					value={getShortDate(member?.dateOfService)}
				/>
			</Stack>
			<Stack flexGrow={0} width={'50%'} rowGap={1.875}>
				<CompositeTypography
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
					label={tClientLabels('memberDetail.labels.fiscalCode')}
					value={member?.fiscalCode ? member?.fiscalCode : '-'}
				/>
				<CompositeTypography
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
					label={tClientLabels('memberDetail.labels.groupName')}
					value={member?.groupName ? member?.groupName : '-'}
				/>
				<CompositeTypography
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
					label={tClientLabels('memberDetail.labels.dateGenerated')}
					value={getFullDate(member?.dateGenerated)}
				/>
			</Stack>
		</Paper>
	);
};

export default MemberDetail;
