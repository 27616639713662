import { handleChange, setSearch } from 'feature/form/formSlice';
import {
	setDownloadAll,
	setPaymentExcludeIds,
	setPaymentsIds,
} from 'feature/payments/paymentsSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SearchHideIcon } from 'assett/icons/search_hide_icon.svg';
import { ReactComponent as SearchIcon } from 'assett/icons/search_icon.svg';
import HeaderCellWithSorting from 'components/commons/list/HeaderCellWithSorting';
import useDevice from 'hooks/DesktopDevice.hook';
import { FC } from 'react';
import { AppDispatch, RootState } from 'store/store';
import Box from 'styles/box/Box';
import IconButton from 'styles/button/IconButton';
import Checkbox from 'styles/checkbox/Checkbox';
import DatePicker from 'styles/datePicker/DatePicker';
import Stack from 'styles/stack/Stack';
import palette from 'styles/theme/Palette';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const PaymentsHeader: FC = () => {
	const { downloadAll } = useSelector((store: RootState) => store.payments);
	const { search, dateOfService } = useSelector(
		(store: RootState) => store.form,
	);
	const { isDesktop } = useDevice();
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const handleCheckDownload = () => {
		if (downloadAll) {
			dispatch(setDownloadAll(false));
			dispatch(setPaymentsIds([]));
			dispatch(setPaymentExcludeIds([]));
		} else {
			dispatch(setDownloadAll(true));
		}
	};

	return (
		<Stack
			direction={'row'}
			position="sticky"
			top={0}
			zIndex={10}
			width={'100%'}
			sx={{
				px: !isDesktop ? 0.8 : 1.25,
				backgroundColor: palette.grey6.main,
			}}
		>
			<Stack
				flexGrow={1}
				direction={'row'}
				sx={{ height: 76 }}
				alignItems={'center'}
			>
				<Checkbox
					label=""
					onClick={handleCheckDownload}
					checked={downloadAll}
					sx={{ p: 0 }}
					accessibilitylabel={tClientLabels('payment.labels.selectAllPayments')}
				/>
				<HeaderCellWithSorting
					name="paymentRunDate"
					widthBox={!!isDesktop ? 336 : 220}
					widthInput={!!isDesktop ? 300 : 220}
				>
					<Box sx={{ width: !!isDesktop ? 300 : 213 }}>
						<DatePicker
							variant="field"
							label={tClientLabels('forms.header.paymentRunDate')}
							value={dateOfService}
							onChange={value => {
								dispatch(
									handleChange<any>({
										name: 'dateOfService',
										value: value,
									}),
								);
							}}
						/>
					</Box>
				</HeaderCellWithSorting>
				<HeaderCellWithSorting
					name="paymentRunId"
					widthBox={!!isDesktop ? 245 : 220}
					widthInput={213}
				/>
				<HeaderCellWithSorting
					name="totalAmount"
					widthBox={!!isDesktop ? 245 : 220}
					widthInput={213}
				/>
			</Stack>
			<Stack
				flexGrow={0}
				alignItems={'end'}
				height={76}
				justifyContent={'center'}
				sx={{ ml: 1.5 }}
			>
				<IconButton
					onClick={() => dispatch(setSearch(!search))}
					sx={{ ml: !!isDesktop ? 0 : 4.2 }}
					aria-label={tClientLabels('commons.buttons.search')}
				>
					{search ? <SearchHideIcon /> : <SearchIcon />}
				</IconButton>
			</Stack>
		</Stack>
	);
};

export default PaymentsHeader;
