import { ReactComponent as PlusIcon } from 'assett/icons/plus_icon.svg';
import { uniqueId } from 'lodash';
import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Box, Button, TableCell, TableRow } from 'styles';
import { useTranslate } from 'stylesHooks';
import { IClaim, IClaimComponents } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import OtherReceiptItemRow from './OtherReceiptItemRow';

const OtherItemsSection: FC<{
	editMode: boolean;
	isError: boolean;
	tempAmounts: IClaim['amount'];
	setTempAmounts: Dispatch<SetStateAction<IClaim['amount']>>;
	checkIsError: (row: IClaimComponents) => boolean;
	handleChangeAmounts: (
		formattedValue: string,
		serviceId: number | null,
		key: string,
	) => void;
	isOtherComponent: (row: IClaimComponents) => boolean;
}> = ({
	editMode,
	isError,
	tempAmounts,
	setTempAmounts,
	checkIsError,
	handleChangeAmounts,
	isOtherComponent,
}) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { otherServices } = useSelector((state: RootState) => state.claim);

	const otherComponents = tempAmounts.components.filter(el =>
		isOtherComponent(el),
	);

	const normalComponentsNumber =
		(tempAmounts.components.length || 0) - (otherComponents?.length || 0);

	const handleClick = () => {
		const newComponents = [
			...tempAmounts.components,
			{
				serviceName: '',
				retailAmount: 0,
				grossRetails: 0,
				serviceId: null,
				rowId: uniqueId(),
			},
		];

		const newTempAmounts = {
			...tempAmounts,
			components: newComponents,
		};

		setTempAmounts(newTempAmounts);
	};

	const removeOtherComponent = (rowNumber: number) => {
		const newComponents = [...tempAmounts.components].filter(
			(_, index) => index !== rowNumber,
		);

		const newTempAmounts = {
			...tempAmounts,
			components: newComponents,
		};

		setTempAmounts(newTempAmounts);
	};

	const handleChangeServiceName = (rowNumber: number, serviceId: number) => {
		const newComponents = [...tempAmounts.components].map((el, index) => {
			if (index === rowNumber) {
				return {
					...el,
					serviceId: serviceId,
				};
			}

			return el;
		});

		const newTempAmounts = {
			...tempAmounts,
			components: newComponents,
		};

		setTempAmounts(newTempAmounts);
	};

	return (
		<>
			{editMode ? (
				<TableRow>
					<TableCell colSpan={12} sx={{ py: '20px' }}>
						<Button
							startIcon={
								<Box
									sx={{
										display: 'flex',
										direction: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<PlusIcon color="inherit" />
								</Box>
							}
							variant="linkDark"
							onClick={handleClick}
							disabled={
								tempAmounts.components.filter(el => isOtherComponent(el)).length >=
								otherServices.length
							}
							sx={{
								display: 'flex',
								direction: 'row',
								alignItems: 'center',
								gap: '8px',
								'& .MuiButton-startIcon': { width: '16px' },
							}}
						>
							{tClientLabels('newClaim.priceEstimate.otherReceiptItems.button')}
						</Button>
					</TableCell>
				</TableRow>
			) : null}
			{otherComponents.map((el, index) => (
				<OtherReceiptItemRow
					key={index}
					row={el}
					removeComponent={removeOtherComponent}
					handleChangeAmounts={handleChangeAmounts}
					tempAmounts={tempAmounts}
					handleChangeServiceName={handleChangeServiceName}
					editMode={editMode}
					isError={isError}
					checkIsError={checkIsError}
					isOtherComponent={isOtherComponent}
					rowNumber={normalComponentsNumber + index}
				/>
			))}
		</>
	);
};

export default OtherItemsSection;
