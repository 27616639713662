import { ThunkApiType } from 'types/storeTypes';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { MemberThunkProps } from 'types/Members';
import { createUrlFromBlob } from 'utils/download';

export const getMemberDetailsThunk = async (
	props: MemberThunkProps & { callback: () => void },
	thunkAPI: ThunkApiType,
) => {
	let getMemberDetailsURL = `/member/portalMemberDetail`;

	const { subscriptionId, dateOfService } = props;

	if (subscriptionId && dateOfService !== '') {
		getMemberDetailsURL =
			getMemberDetailsURL +
			`?subscriptionId=${subscriptionId}&dateOfService=${dateOfService}`;

		try {
			const response = await authFetch.get(getMemberDetailsURL);
			props.callback();
			return { ...response.data, subscriptionId };
		} catch (error: ThunkApiType) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};

export const downloadMemberBenefitsThunk = async (
	{
		subscriptionId,
		dateOfService,
		fileName,
		print,
	}: {
		subscriptionId: number;
		dateOfService: string;
		fileName: string;
		print: boolean;
	},
	thunkAPI: ThunkApiType,
) => {
	const url = `/members/subscriptions/${subscriptionId}/providerBenefits/download`;
	try {
		const response = await authFetch.post(
			url,
			{ dateOfService },
			{
				responseType: 'blob',
			},
		);

		return {
			documentUrl: createUrlFromBlob(response.data, print),
			print,
			fileName,
		};
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
