import {
	resetDialogConfirm,
	setDialogOpen,
	setInjectionState,
} from 'feature/hooks/dialogSlice';
import { setCookies } from 'feature/user/userSlice';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import { saveClaim } from 'feature/claims/claimSlice';
import { resetLimitations } from 'feature/limitations/limitationsSlice';
import {
	CHECK_CONNECTION_ERROR,
	COOKIES_DIALOG,
	DOCUMENTATION_INFO,
	GENERIC_ERROR,
	HARD_LOGOUT,
	NO_MAT_NO_EXAM,
	NO_PROVIDER,
	SHOW_LIMITATIONS,
	SUBMIT_LATER,
	VERSION,
	VISION_CHANGE_ALERT,
} from 'types/dialogtypes';
import { useLogout } from './login.hook';

const useDialogConfirm = () => {
	const dispatch = useDispatch<AppDispatch>();

	const { limitations } = useSelector((state: RootState) => state.limitations);
	const { type } = useSelector((store: RootState) => store.hooks.dialog);

	const mapping = useCallback(
		(type: string, text: string) => {
			switch (type) {
				case SHOW_LIMITATIONS:
					return {
						title: 'dialog.confirm.showLimitations.title',
						content: 'dialog.confirm.showLimitations.content',
						multi: true,
						data: limitations.map(
							(e: { attributes: { title: string; body: any } }) => ({
								title: e.attributes.title,
								content: e.attributes.body,
							}),
						),
						type: type,
						buttonClose: true,
					};
				case VISION_CHANGE_ALERT:
					//triggered by slice. not configured here
					return {};
				case NO_MAT_NO_EXAM:
					return {
						title: 'dialog.confirm.noMatNoExam.title',
						content: 'dialog.confirm.noMatNoExam.content',
						type: type,
						buttonClose: true,
					};
				case SUBMIT_LATER:
					return {
						title: 'dialog.confirm.submitLater.title',
						content: 'dialog.confirm.submitLater.content',
						labelCancel: 'dialog.buttons.cancel',
						labelConfirm: 'dialog.buttons.continue',
						variantButtonCancel: 'outlined',
						type: type,
						buttonClose: true,
					};
				case GENERIC_ERROR:
					return {
						title: 'dialog.confirm.error.title',
						content: 'dialog.confirm.error.content',
						labelCancel: 'dialog.buttons.cancel',
						variantButtonCancel: 'contained',
						type: type,
						buttonClose: true,
					};
				case VERSION:
					return {
						title: 'dialog.version.title',
						content: 'dialog.version.contentTitle',
						type: type,
						buttonClose: true,
					};
				case DOCUMENTATION_INFO:
					return {
						title: 'dialog.confirm.prescriptionInfo.title',
						content: 'dialog.confirm.prescriptionInfo.content',
						caption: 'dialog.confirm.prescriptionInfo.caption',
						type: type,
						buttonClose: true,
					};
				default:
					break;
			}
		},
		[limitations],
	);

	const closeDialog = useCallback(() => {
		dispatch(setDialogOpen(false));
		dispatch(resetDialogConfirm());

		switch (type) {
			case SHOW_LIMITATIONS:
				dispatch(resetLimitations());
				break;
			default:
				break;
		}
	}, [dispatch, type]);

	const handleOpen = useCallback(
		(type: string, text?: string) => {
			dispatch(setInjectionState(mapping(type, text || '')));
			dispatch(setDialogOpen(true));
		},
		[dispatch, mapping],
	);

	const handleCancel = useCallback(() => {
		switch (type) {
			case COOKIES_DIALOG:
				dispatch(setCookies('n'));
				break;
			default:
				break;
		}
		dispatch(setDialogOpen(false));
	}, [dispatch, type]);

	const handleLogout = useLogout();
	const handleConfirm = useCallback(() => {
		dispatch(resetDialogConfirm());
		switch (type) {
			case COOKIES_DIALOG:
				dispatch(setCookies('y'));
				break;
			case SUBMIT_LATER:
				dispatch(saveClaim({ switchPage: false, redirect: true }));
				break;
			case NO_PROVIDER:
			case CHECK_CONNECTION_ERROR:
			case HARD_LOGOUT:
				handleLogout();
				break;
			default:
				break;
		}
		dispatch(setDialogOpen(false));
	}, [dispatch, handleLogout, type]);

	return useMemo(
		() => ({
			open: handleOpen,
			close: closeDialog,
			cancel: handleCancel,
			confirm: handleConfirm,
		}),
		[closeDialog, handleCancel, handleConfirm, handleOpen],
	);
};

export default useDialogConfirm;
