import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import FileSaver from 'file-saver';
import { IState } from 'types/Member';
import {
	downloadMemberBenefitsThunk,
	getMemberDetailsThunk,
} from './memberThunk';

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
	member: null,
	pending: false,
	printUrl: '',
};

export const getMemberDetails = createAsyncThunk(
	'member/getMemberDetails',
	getMemberDetailsThunk,
);

export const downloadMemberBenefits = createAsyncThunk(
	'members/downloadMembersBenefits',
	downloadMemberBenefitsThunk,
);

const memberSlice = createSlice({
	name: 'member',
	initialState,
	reducers: {
		resetMember: () => initialState,
	},
	extraReducers: builder => {
		builder
			.addCase(getMemberDetails.fulfilled, (state: IState, response: any) => {
				const { payload, meta } = response;
				state.member = {
					...payload,
					memberId: meta.arg.memberId,
					subscriptionUuid: meta.arg.subscriptionUuid,
				};
			})
			.addCase(downloadMemberBenefits.fulfilled, (state, { payload }) => {
				if (!payload.print) {
					FileSaver.saveAs(payload.documentUrl, payload.fileName);
				}

				if (payload.print) {
					state.printUrl = payload.documentUrl;
				}
			});
	},
});

export const { resetMember } = memberSlice.actions;
export default memberSlice.reducer;
