import { useMediaQuery, useTheme } from 'stylesHooks';

const useDevice = () => {
	const theme = useTheme();

	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const isTabletLandScape = useMediaQuery(theme.breakpoints.between(900, 1200), {
		defaultMatches: true,
		noSsr: true,
	});

	return {
		isDesktop,
		isTablet,
		isMobile,
		isTabletLandScape,
		isTabletDevice: isMobile || isTablet,
	};
};

export default useDevice;
