import useDialogConfirm from 'hooks/DialogConfirm.hook';
import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createClaim, getPrescriptions } from 'feature/claims/claimSlice';
import { resetForm } from 'feature/form/formSlice';
import { getLimitations } from 'feature/limitations/limitationsSlice';
import { downloadMemberBenefits } from 'feature/members/memberSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import mobile from 'is-mobile';
import { useNavigate } from 'react-router';
import { AppDispatch, RootState } from 'store/store';
import { BoxOverflow } from 'styles';
import Box from 'styles/box/Box';
import Button from 'styles/button/Button';
import Stack from 'styles/stack/Stack';
import { useTranslate } from 'stylesHooks';
import { SHOW_LIMITATIONS } from 'types/dialogtypes';
import { TRANSLATION_KEYS } from 'utils/constants';
import { ReactComponent as DownloadIcon } from '../../assett/icons/download.svg';
import { ReactComponent as PrintIcon } from '../../assett/icons/print.svg';
import MemberPlans from './components/MemberBenefits.component';
import MemberDetail from './components/MemberDetail.component';

const CheckEligiblityMemberDetail: FC = () => {
	const { isDesktop } = useDevice();
	const isMobile = mobile({ tablet: true, featureDetect: true });
	const { member, pending, printUrl } = useSelector(
		(store: RootState) => store.member,
	);
	const { limitations } = useSelector((store: RootState) => store.limitations);
	const { profile, features } = useSelector((store: RootState) => store.user);
	const navigate = useNavigate();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dialog = useDialogConfirm();
	const dispatch = useDispatch<AppDispatch>();
	const iframeRef = useRef<HTMLIFrameElement>(null);

	const featuresRole = features.map(el => el.code);

	const handleShowLimitations = () => {
		dispatch(getLimitations(''));
		dialog.open(SHOW_LIMITATIONS);
	};
	useEffect(() => {
		if (!pending && !member) {
			navigate('/check-and-submit');
		}
	}, [pending, member, navigate]);

	useEffect(() => {
		dispatch(resetForm());
	}, [dispatch]);

	useEffect(() => {
		if (limitations.length > 0) {
			dialog.open(SHOW_LIMITATIONS);
		}
	}, [dialog, limitations]);

	useEffect(() => {
		if (printUrl !== '') {
			// this is a workaround because we should wait for the iframe to load the content before printing
			// but we dont have any event we can use with Ref
			// so we have to put a timeout
			// also we do not have an indication when the user closes the dialog
			// because the events are not fully supported in every browser
			setTimeout(() => {
				iframeRef.current?.contentWindow?.print();
			}, 10);
		}
	}, [printUrl]);

	const downloadFile = (print: boolean) => {
		dispatch(
			downloadMemberBenefits({
				subscriptionId: member?.subscriptionId as number,
				dateOfService: member?.dateOfService.replace('T00:00Z', '') as string,
				fileName: `${member?.memberName.replace(' ', '_')}.pdf`,
				print,
			}),
		);
	};

	const BenefitContent = (
		<Box width={'100%'}>
			<Box width={'100%'} sx={{ pb: 2 }}>
				<Stack rowGap={isDesktop ? '60px' : 2.5}>
					<MemberDetail />
					<MemberPlans />
					<iframe
						title="print-iframe"
						ref={iframeRef}
						src={printUrl}
						id="print-iframe"
						style={{ display: 'none' }}
					/>
				</Stack>
				<Stack
					height={24}
					direction={'row'}
					width={'100%'}
					justifyContent={'space-between'}
					sx={{ mt: 3.125 }}
				>
					<Button variant="underline" onClick={handleShowLimitations}>
						{tClientLabels('memberDetail.labels.showLimitations')}
					</Button>
					{isMobile ? (
						<Button variant="underline" onClick={() => downloadFile(false)}>
							<DownloadIcon />
							{tClientLabels('memberDetail.labels.downloadBenefits')}
						</Button>
					) : (
						<Button variant="underline" onClick={() => downloadFile(true)}>
							<PrintIcon />
							{tClientLabels('memberDetail.labels.printBenefits')}
						</Button>
					)}
				</Stack>
			</Box>
			<Stack>
				{featuresRole.includes('submit-claims') && member?.eligible ? (
					<Button
						sx={{
							width: 'max-content',
							mt: isDesktop ? 3 : 4.5,
							justifyContent: 'center',
						}}
						variant="contained"
						onClick={() => {
							dispatch(
								createClaim({
									memberFullName: member?.memberName,
									beneficiaryMemberId: member?.memberId,
									memberId: member?.memberId,
									memberSubscriptionId: member?.subscriptionId,
									beneficiarySubscriptionId: member?.subscriptionId,
									providerId: profile?.providerId,
									claimDate: member?.dateOfService,
									rxChangeAttestation: member?.rxCheck === 0 ? 0 : null,
								}),
							);
							dispatch(getPrescriptions({}));
							navigate(`/check-and-submit/${member?.memberName}/new-claim`);
						}}
					>
						{tClientLabels('check-and-submit.buttons.newClaim')}
					</Button>
				) : null}
			</Stack>
		</Box>
	);

	return (
		<Stack
			direction="row"
			justifyContent={'space-between'}
			sx={{ height: '98%' }}
		>
			<BoxOverflow variant="y" sx={{ width: '100%' }}>
				{BenefitContent}
			</BoxOverflow>
		</Stack>
	);
};

export default CheckEligiblityMemberDetail;
