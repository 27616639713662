import { useDispatch, useSelector } from 'react-redux';
import { formatDateWithoutZulu, formatPrice, getShortDate } from 'utils/utils';

import { ReactComponent as ChevronRightIcon } from 'assett/icons/chevron_right_icon.svg';
import { setSelectOne } from 'feature/payments/paymentsSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import IconButton from 'styles/button/IconButton';
import Checkbox from 'styles/checkbox/Checkbox';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useTranslate } from 'stylesHooks';
import { PaymentPathnames } from 'types/Payment';
import { IPayments } from 'types/Payments';
import { TRANSLATION_KEYS } from 'utils/constants';

export const PaymentsList: FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { payments, paymentsIds } = useSelector(
		(store: RootState) => store.payments,
	);
	const navigate = useNavigate();
	const { isDesktop } = useDevice();

	const handleClick = (payment: IPayments) => {
		navigate(
			`/${PaymentPathnames.VIEW_PAYMENT_HISTORY}/${
				payment.paymentRunId
			}|${formatDateWithoutZulu(payment.paymentRunDate)}`,
		);
	};

	const List = payments.map((payment: IPayments) => {
		return (
			<Paper
				variant="outlined"
				sx={{
					borderWidth: 0,
					mb: 0.5,
					width: '100%',
					minWidth: 'fit-content',
				}}
				key={payment.paymentRunId + payment.paymentRunDate}
			>
				<Stack
					direction={'row'}
					alignItems={'center'}
					height={!!isDesktop ? 52 : 38}
					sx={{ p: !!isDesktop ? 1.25 : 0.8 }}
				>
					<Stack flexGrow={1} direction={'row'} alignItems={'center'}>
						<Checkbox
							label=""
							accessibilitylabel={tClientLabels('payment.labels.selectPayment')}
							key={payment.paymentRunId}
							sx={{ p: 0, ml: 0 }}
							checked={paymentsIds.includes(
								payment.paymentRunId + payment.paymentRunDate,
							)}
							onClick={() =>
								dispatch(setSelectOne(payment.paymentRunId + payment.paymentRunDate))
							}
						/>

						<Typography
							variant="body"
							sx={{ width: !!isDesktop ? 336 : 220, pl: 1.5 }}
						>
							{getShortDate(payment.paymentRunDate)}
						</Typography>

						<Typography
							variant="body"
							sx={{ width: !!isDesktop ? 245 : 220, ml: 1.5 }}
						>
							{`#${payment.paymentRunId}`}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 245 : 220 }}>
							{formatPrice(payment.totalAmount)}
						</Typography>
					</Stack>
					<Stack flexGrow={0}>
						<IconButton
							onClick={() => handleClick(payment)}
							sx={{ cursor: 'pointer' }}
							aria-label={tClientLabels('payment.labels.viewDetails')}
						>
							<ChevronRightIcon />
						</IconButton>
					</Stack>
				</Stack>
			</Paper>
		);
	});

	const progressIndicator = useSelector(
		(state: RootState) => state.progressIndicator,
	);

	return (
		<Stack sx={{ mb: 0.5 }}>
			{List.length > 0 ? (
				List
			) : (
				<Typography variant="bodyLink" sx={{ width: '100%', padding: 1.2 }}>
					{progressIndicator?.isLoading
						? ''
						: tClientLabels('view-payment-history.noPaymentsFound')}
				</Typography>
			)}
		</Stack>
	);
};

export default PaymentsList;
