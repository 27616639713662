import { Link } from 'styles';

const SkipToMainContentLink = () => {
	return (
		<Link
			href="#main"
			sx={{
				position: 'absolute',
				top: 'auto',
				left: -9999,
				width: 1,
				height: 1,
				overflow: 'hidden',
				zIndex: -999,
				'&:focus, &:active': {
					left: 'auto',
					top: 'auto',
					width: '30%',
					height: 'auto',
					overflow: 'auto',
					margin: '10px 35%',
					padding: '5px',
					borderRadius: '15px',
					border: '4px solid black',
					textAlign: 'center',
					fontSize: '1.2em',
					zIndex: 999,
				},
			}}
		>
			Skip to main content
		</Link>
	);
};

export default SkipToMainContentLink;
